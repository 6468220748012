
.size-10
{
    font-size: 10px;
}
.m-10
{   
    margin: 10px;
    font-weight: 500;
}
.m-15
{   
    margin: 15px;
}

.text-justify {
	text-align: justify !important;
}
.text-center {
	text-align: center !important;
}
.b-800{
    font-weight: 800;
}